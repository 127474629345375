import React from "react"

const SnsLink = props => {
  if (props.instagram_url || props.twitter_url) {
    return (
      <div className="sns-list-wrapper">
        <ul className="sns-list">
          <li className="">
            <span>Store SNS</span>
          </li>
          {props.instagram_url && (
            <li>
              <a
                href={props.instagram_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://balukoweb.blob.core.windows.net/images/store/sns/instagram-icon.png"
                  alt="instagram-icon"
                />
              </a>
            </li>
          )}

          {props.twitter_url && (
            <li>
              <a
                href={props.twitter_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://balukoweb.blob.core.windows.net/images/store/sns/twitter-icon.png"
                  alt="twitter-icon"
                />
              </a>
            </li>
          )}
        </ul>
      </div>
    )
  }
}
export default SnsLink
