import { DateTime } from "luxon"

const CheckStoreCampaign = store => {
  const now = DateTime.local()
    .setZone("Asia/Tokyo")
    .toFormat("yyyy-MM-dd HH:mm:ss z")
  const bannerExpired = store.banner_expired_at
    ? DateTime.fromISO(store.banner_expired_at)
        .setZone("Asia/Tokyo")
        .toFormat("yyyy-MM-dd HH:mm:ss z")
    : null
  return now < bannerExpired
}

export default CheckStoreCampaign
