import React, { useState, useEffect } from "react"
import { getCurrentDate } from "service/store/getCurrentDate"
import { getConvertMachineName } from "service/store/getConvertMachineName"
import { sliceProductNumber } from "service/store/sliceProductNumber"
import { FetchToseiStatus } from "service/store/fetchToseiStatus"
import { getUnitNumber } from "service/store/getUnitNumber"

const ToseiStatus = props => {
  const { store_id } = props
  const [toseiState, setToseiState] = useState({
    message: "",
    operationStatus: [],
  })

  const getToseiStatus = async id => {
    const result = await FetchToseiStatus(id)
    setToseiState({
      ...toseiState,
      message: result.message,
      operationStatus: result.result.operation_status,
    })
  }

  useEffect(() => {
    getToseiStatus(store_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="tosei-status">
      {toseiState.operationStatus && toseiState.operationStatus.length > 0 ? (
        <>
          <div className="heder-contents">
            <ul>
              <li className="header-title">稼働状況</li>
              <li className="current-date">
                {getCurrentDate()}
                <span>更新</span>
              </li>
            </ul>
          </div>
          <table>
            <thead>
              <tr>
                <th>機器番号</th>
                <th>機種名</th>
                <th>稼働状況</th>
                <th>残り時間</th>
              </tr>
            </thead>
            <tbody>
              {toseiState.operationStatus.map((x, i) => {
                return (
                  <tr key={`row_${i}`}>
                    <td>
                      <span className="col-num">
                        {getUnitNumber(x.device_model_name, x.unit_no)}
                      </span>
                    </td>
                    <td>
                      <span className="col-txt">
                        {sliceProductNumber(
                          getConvertMachineName(x.device_model_name)
                        )}
                      </span>
                    </td>
                    <td>
                      {x.status.operation?.code === "1" ? (
                        <img
                          src="https://balukoweb.blob.core.windows.net/images/store/tosei/working.svg"
                          className="working"
                          alt="working"
                        />
                      ) : (
                        <span className="col-txt">空き</span>
                      )}
                    </td>
                    <td>
                      {x.status.remaining_time !== 0 ? (
                        <>
                          <span className="col-num">
                            {x.status.remaining_time}
                          </span>
                          <span className="minutes-label">分</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default ToseiStatus
