import axios from "axios"

export const FetchToseiStatus = async storeId => {
  const baseUrl = process.env.FUNCTIONS_API_URL
  const headers = {
    "x-functions-key": process.env.FUNCTIONS_API_KEY,
    "Content-Type": "application/json",
  }
  const result = axios
    .get(`${baseUrl}/machine_status_api?store_id=${storeId}`, { headers })
    .then(response => {
      const result = response.data
      return result
    })
    .catch(e => {
      console.log(e)
      const result = e.response.data
      return result
    })
  return result
}
