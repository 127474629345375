import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"
import { Carousel } from "react-responsive-carousel"
import SnsLink from "components/parts/snsLink"
import StoreDetailService from "components/parts/storeDetailService"
import CheckStoreCampaign from "service/store/checkStoreCampaign"
import CheckStoreOpen from "service/store/checkStoreOpen"
import ToseiStatus from "components/store/toseiStatus"

const StoreDetailPage = ({ data }) => {
  const storeData = data.strapiStore
  if (
    typeof storeData.service === "string" ||
    storeData.service instanceof String
  ) {
    storeData.service = storeData.service.split(";").sort()
  }
  const tosei_store_id = data.strapiStore.tosei_store_id

  return (
    <Layout>
      <div className="store-page design-style">
        <div className="contents">
          <div className="store-detail">
            {/* /ページタイトル */}
            <div className="store-detail-contents">
              {/* 店舗名 */}
              <div className="title">
                <h1 className="store-title e tx-center">
                  {data.strapiStore.name}
                </h1>
              </div>
              {/* /店舗名 */}

              <div className="campaign-banner">
                {data.strapiStore.banner && CheckStoreCampaign(storeData) && (
                  <Link to={data.strapiStore?.banner_link}>
                    <img
                      src={data.strapiStore.banner}
                      className="banner-image"
                      alt="campaign"
                    />
                  </Link>
                )}
              </div>
              <div className="campaign-text">
                {ReactHtmlParser(data.strapiStore?.campaign_text ?? "")}
              </div>

              <div className="contents-wrap">
                <div className="store-images">
                  {CheckStoreOpen(storeData.opening_date) && (
                    <Carousel
                      autoPlay={true}
                      infiniteLoop={true}
                      showStatus={false}
                      showThumbs={false}
                      swipeable={false}
                    >
                      <img
                        src={storeData.image1 ?? ""}
                        loading="lazy"
                        width="100%"
                        alt=""
                      />
                      {data.strapiStore.image2 && (
                        <img
                          src={storeData.image2 ?? ""}
                          loading="lazy"
                          width="100%"
                          alt=""
                        />
                      )}
                      <img
                        src={storeData.image3 ?? ""}
                        loading="lazy"
                        width="100%"
                        alt=""
                      />
                      {storeData.image4 && (
                        <img
                          src={storeData.image4 ?? ""}
                          loading="lazy"
                          width="100%"
                          alt=""
                        />
                      )}

                      {storeData.image5 && (
                        <img
                          src={storeData.image5 ?? ""}
                          loading="lazy"
                          width="100%"
                          alt=""
                        />
                      )}
                    </Carousel>
                  )}

                  {!CheckStoreOpen(storeData.opening_date) && (
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/store/form/before_open.jpg"
                      loading="lazy"
                      width="100%"
                      alt="comming soon"
                    />
                  )}
                </div>

                {/* 地図 */}
                <div className="google-map">
                  {ReactHtmlParser(data.strapiStore?.googlemap ?? "")}
                </div>
                {/* /地図 */}
              </div>
              {/* 詳細情報 */}
              <div className="info">
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>店舗名</td>
                      <td>{data.strapiStore.name ?? ""}</td>
                    </tr>
                    <tr>
                      <td>住所</td>
                      <td>{data.strapiStore.address ?? ""}</td>
                    </tr>
                    <tr>
                      <td>駐車場</td>
                      <td>{data.strapiStore.parking ?? ""}</td>
                    </tr>
                    <tr>
                      <td>営業時間・休業日</td>
                      <td>
                        {ReactHtmlParser(
                          data.strapiStore?.business_hours ?? ""
                        )}
                      </td>
                    </tr>
                    {data.strapiStore?.phone && (
                      <tr>
                        <td>電話番号</td>
                        <td>{ReactHtmlParser(data.strapiStore?.phone)}</td>
                      </tr>
                    )}
                    {data.strapiStore?.laundrich && (
                      <tr>
                        <td>稼働状況</td>
                        <td>
                          <a
                            href={data.strapiStore.laundrich}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data.strapiStore.laundrich ?? ""}
                          </a>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>コールセンター</td>
                      <td>{data.strapiStore.emergency ?? ""}</td>
                    </tr>
                    {data.strapiStore.is_baluko_card && (
                      <tr>
                        <td>バルコカード</td>
                        <td>バルコカードをご利用いただけます。<Link to="/self-laundry/balukocard" className="page-link">詳細はこちら＞＞</Link></td>
                    </tr>
                    )}
                    
                  </tbody>
                </table>
              </div>
              {/* /詳細情報 */}
              <ul className="icon service-list">
                {data.strapiStore.service.map((service, index) => {
                  return <StoreDetailService service={service} index={index} />
                })}
              </ul>

              {tosei_store_id !== null && (
                <ToseiStatus store_id={tosei_store_id} />
              )}
            </div>

            {data.strapiStore.is_baluko_app && (
              <div className="app-contents">
                <div className="left-contents">
                  <img
                    src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/baluko_app_logo.svg"
                    className="title-image"
                    alt="Balukoアプリロゴ"
                  />
                  <p className="text">
                    Baluko Laundry Placeアプリで機器稼働＆WEB決済を
                    <br />
                    ご利用いただけます。
                    <a
                      href="https://app.baluko.jp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="read-more en">Read More</span>
                    </a>
                  </p>
                  <div className="pc install-area">
                    <p className="install-text">
                      インストールは
                      <br />
                      こちらから
                    </p>
                    <a
                      href="https://apps.apple.com/jp/app/id1619758573?mt=8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_apple.png"
                        className="install-image pc-ml10"
                        alt="App Storeインストールリンク"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=jp.baluko.app"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_googleplay.png"
                        className="install-image pc-ml10"
                        alt="Google Play Storeインストールリンク"
                      />
                    </a>
                  </div>
                </div>
                <div className="right-contents">
                  <div className="image-wrap">
                    <img
                      src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/app_ill02.svg"
                      className="illust-image"
                      alt="Balukoアプリイラストイメージ"
                    />
                  </div>
                  <div className="sp install-area">
                    <p className="install-text">
                      インストールは
                      <br />
                      こちらから
                    </p>
                    <a
                      href="https://apps.apple.com/jp/app/id1619758573?mt=8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_apple.png"
                        className="install-image pc-ml10 sp-ml10"
                        alt="App Storeインストールリンク"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=jp.baluko.app"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_googleplay.png"
                        className="install-image pc-ml10 sp-ml10"
                        alt="Google Play Storeインストールリンク"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {data.strapiStore.is_smart_laundry && (
              <div className="app-contents">
                <div className="left-contents">
                  <img
                    src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/sl_app_logo.svg"
                    className="title-image"
                    alt="Balukoアプリロゴ"
                  />
                  <p className="text">
                    Smart Laundryアプリで機器稼働＆WEB決済を
                    <br />
                    ご利用いただけます。<Link to="/self-laundry/smartlaundry" className="smartLaundryPageLink">SmartLaundryアプリについて</Link>
                  </p>
                  <div className="pc install-area">
                    <p className="install-text">
                      インストールは
                      <br />
                      こちらから
                    </p>
                    <a
                      href="https://apps.apple.com/jp/app/smart-laundry/id1313142194"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_apple.png"
                        className="install-image pc-ml10"
                        alt="App Storeインストールリンク"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=jp.smart_laundry&hl=ja&gl=US"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_googleplay.png"
                        className="install-image pc-ml10"
                        alt="Google Play Storeインストールリンク"
                      />
                    </a>
                  </div>
                </div>
                <div className="right-contents">
                  <div className="image-wrap">
                    <img
                      src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/SL_image.png"
                      className="sl-illust-image"
                      alt="Smart Laundryイメージイラスト"
                    />
                  </div>
                  <div className="sp install-area">
                    <p className="install-text">
                      インストールは
                      <br />
                      こちらから
                    </p>
                    <a
                      href="https://apps.apple.com/jp/app/smart-laundry/id1313142194"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_apple.png"
                        className="install-image pc-ml10 sp-ml10"
                        alt="App Storeインストールリンク"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=jp.smart_laundry&hl=ja&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="install-link"
                    >
                      <img
                        src="https://okulabwebstorage.blob.core.windows.net/baluko-image/store/banner_googleplay.png"
                        className="install-image pc-ml10 sp-ml10"
                        alt="Google Play Storeインストールリンク"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}

            <SnsLink
              instagram_url={data.strapiStore.instagram_url}
              twitter_url={data.strapiStore.twitter_url}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const Head = ({ data }) => (
  <Seo
    title={data.strapiStore.name}
    description={`使いやすさ、居心地の良さ、クオリティの高い仕上がりを提供するセルフランドリー。ふとん洗濯対応の大型洗濯乾燥機、スニーカー洗濯機、Wi-Fi完備。服にも肌にも環境に優しいオリジナル洗剤を使用しています。 | ${data.strapiStore.name} | ${data.strapiStore.address}`}
  />
)
export default StoreDetailPage

export const query = graphql`
  query ($page_slug: String) {
    strapiStore(page_slug: { eq: $page_slug }) {
      id
      name
      address
      business_hours
      emergency
      googlemap
      image1
      image2
      image3
      image4
      image5
      service
      twitter_url
      instagram_url
      laundrich
      parking
      phone
      banner
      banner_link
      banner_expired_at
      campaign_text
      is_baluko_app
      is_smart_laundry
      opening_date
      tosei_store_id
      is_baluko_card
    }
  }
`
