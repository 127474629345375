export const getConvertMachineName = machineName => {
  if (machineName.includes("SF-155C")) {
    return machineName.replace("小型", "Mサイズ")
  }
  if (machineName.includes("SF-275C")) {
    return machineName.replace("中型", "Lサイズ")
  }
  if (machineName.includes("小型")) {
    return machineName.replace("小型", "Sサイズ")
  }
  if (machineName.includes("中型")) {
    return machineName.replace("中型", "Mサイズ")
  }
  if (machineName.includes("大型")) {
    return machineName.replace("大型", "Lサイズ")
  }
  return machineName
}
